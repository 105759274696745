@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply bg-green-700 border border-r-2 text-cyan-50 px-5 py-1 text-center w-32
}

swal-button--confirm {
    @apply btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32
}

.btnCommon{
    background-color: #283747!important;
}
@media only screen and (min-device-width: 480px){
    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
      width: 90%!important;
      height: 90%!important;
  }
  }

  @media only screen and (min-device-width: 768px){
    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
      width: 90%!important;
      height: 90%!important;
  }
  }


  @media only screen and (min-device-width: 384px){
    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
      width: 90%!important;
      height: 90%!important;
  }
  }

  @media only screen and (min-device-width: 360px){
    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
      width: 90%!important;
      height: 90%!important;
  }
  }

  @media only screen and (min-device-width: 375px){
    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
      width: 90%!important;
      height: 90%!important;
  }
  }

  @media only screen and (min-device-width: 280px){
    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
      width: 90%!important;
      height: 90%!important;
  }
  }